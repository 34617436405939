import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import dashboard from "../../assets/img/projects/Web 2.webp";
import ecom from "../../assets/img/projects/Web 21.webp";
import travel from "../../assets/img/projects/travel.webp";
import web3 from "../../assets/img/projects/web3.webp";
import landing from "../../assets/img/projects/Web 4.webp";
import fintech from "../../assets/img/projects/fintech.webp";

import AddImage2 from "../../assets/img/2.webp";
import Marquee from "react-fast-marquee";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg" style={{marginBottom:"10em"}}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h2 className="font40 extraBold">Our <span className="gradient-text">Awesome</span> Projects</h2>
            <p className="font13">
              We’re proud of the work we do. From custom websites to powerful digital marketing campaigns, here are some of our recent projects 
              <br />
              that have made a real impact for our clients.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <Marquee>
              <ProjectBox
                img={landing}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
              <ProjectBox
                img={web3}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
              <ProjectBox
                img={fintech}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
            </Marquee>
          </div>
          <div className="row textCenter">
          <Marquee direction="right">
              <ProjectBox
                img={dashboard}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
              <ProjectBox
                img={ecom}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
              <ProjectBox
                img={travel}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
            </Marquee>
          </div>
          {/* <div className="row textCenter">
            <Marquee direction="right">
              <ProjectBox
                img={ProjectImg1}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
              <ProjectBox
                img={ProjectImg1}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
              <ProjectBox
                img={ProjectImg1}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => {}}
              />
            </Marquee>
          </div> */}
        </div>
      </div>
      <div className="lightBg">
        <div className="container" data-aos="fade-up">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              {/* <AddLeftInner> */}
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              {/* </AddLeftInner> */}
            </AddLeft>
            <AddRight>
              {/* <h4 className="font15 semiBold">A few words about company</h4> */}
              <h2 className="font40 extraBold">Build Your Digital Presence <br /> with Precision</h2>
              <p className="font12">
                Your website is your digital storefront. With our web development expertise, we create user-friendly, visually stunning, and performance-optimized websites that reflect your brand's identity. Whether you need a simple portfolio or a complex e-commerce platform, we've got you covered.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
                    // action={() => {}}
                  />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
                    // action={() => {}}
                    border
                  />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
