import React from "react";
import Contact from "../components/Home/Contact";
import DevHeader from "../components/Development/DevHeader";
import WhatWeOffer from "../components/Development/WhatWeOffer";
import CaseStudy from "../components/Development/CaseStudy";

export default function Development() {
  return (
    <>
      <DevHeader />
      <WhatWeOffer/>
      <CaseStudy/>
      <Contact />
    </>
  );
}
