import React, { useRef } from "react";
import styled from "styled-components";
import AddImage1 from "../../assets/img/tech/tech.svg";
import ClientSlider from "../Elements/ClientSlider";
import WorkBox from "../Elements/WorkBox";
import PricingTable from "../Elements/PricingTable";
import DevTable from "../Elements/DevTable";

export default function DevelopmentServices() {
  const handleMouseMove = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseEnter = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Set mouse position for gradient effect
    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseLeave = (cardRef) => {
    // Reset gradient effect when leaving the card
    cardRef.current.style.setProperty("--mouse-x", "-9999px");
    cardRef.current.style.setProperty("--mouse-y", "-9999px");
  };

  return (
    <Wrapper id="blog">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h2 className="font40 extraBold">
              <span className="gradient-text">Development </span>Services
            </h2>
            <p className="font13">
              Boost your brand's online success with tailored digital marketing
              services, including SEO, SMM, PPC,
              <br />
              content marketing, and personalized email campaigns.
            </p>
          </HeaderInfo>
          <div className="container" data-aos="fade-up">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <AddRight>
                  <img src={AddImage1} alt="office" />
                </AddRight>
              </AddLeft>
            </Advertising>
            <TablesWrapper className="flexSpaceNull">
              <TableBox>
                <DevTable
                  icon="roller"
                  price="Website Design"
                  // title="Startup Plan"
                  // text=""
                  offers={[
                    { name: "Create stunning designs that:", checked: true },
                    { name: "Reflect your brand identity.", checked: true },
                    {
                      name: "Are visually appealing and engaging.",
                      checked: true,
                    },
                    {
                      name: "Prioritize user experience and functionality.",
                      checked: true,
                    },
                  ]}
                  action={() => {}}
                />
              </TableBox>
              <TableBox>
                <DevTable
                  icon="monitor"
                  price="Website Development"
                  // title="Growth Plan"
                  // text=""
                  offers={[
                    {
                      name: "Stand out with cutting-edge websites that:",
                      checked: true,
                    },
                    {
                      name: "Deliver seamless user experiences.",
                      checked: true,
                    },
                    {
                      name: "Are responsive and mobile-friendly.",
                      checked: true,
                    },
                    {
                      name: "Feature custom designs tailored to your brand.",
                      checked: true,
                    },
                  ]}
                  action={() => {}}
                />
              </TableBox>
            </TablesWrapper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
  }

  @media (max-width: 860px) {
    .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 3em;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 0px 0;
  // padding: 100px 0;
  // position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  margin: auto;
  // width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
  @media (max-width: 860px) {
    img {
      max-width: 100% !important;
    }
  }
`;

const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 45%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
