import React, { useRef } from "react";
import styled from "styled-components";

export default function FAQBox({
  onMouseMove,
  onMouseEnter,
  onMouseLeave,
  data,
  activeAccordion,
  toggleAccordion,
  index
}) {
  const cardRef = useRef(null);

  return (
    <Wrapper
      className="flex flexColumn"
      ref={cardRef}
      onMouseMove={(e) => onMouseMove(e, cardRef)}
      onMouseEnter={(e) => onMouseEnter(e, cardRef)}
      onMouseLeave={() => onMouseLeave(cardRef)}
    >
      <AccordionHeader onClick={() => toggleAccordion()}>
        <span>{data.question}</span>
        <span>{activeAccordion === index ? "-" : "+"}</span>
      </AccordionHeader>
      {activeAccordion === index && (
        <AccordionContent>
          <p>
            {data.answer}
          </p>
        </AccordionContent>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin-bottom: 1em;

  /* Gradient Effect - Hidden by default */
  --mouse-x: -9999px;
  --mouse-y: -9999px;

  background: radial-gradient(
      circle at var(--mouse-x) var(--mouse-y),
      #b10000 0%,
      #b1000050 10%,
      transparent 60%
    ),
    rgba(255, 255, 255, 0.03); /* Default translucent background remains */

  border-image-source: radial-gradient(
    circle at var(--mouse-x) var(--mouse-y),
    #b10000 0%,
    #b1000050 30%,
    transparent 50%
  );
  border-image-slice: 1;
  transition: background 0.3s, transform 0.3s;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; 
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
`;

const AccordionContent = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  margin-top: 5px;
  
  p {
    opacity: 0.5;
  }
`;
