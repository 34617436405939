import React, { useRef } from "react";
import styled from "styled-components";
// Assets
import Education from "../../assets/icons/glass/graduation-cap-icon.webp";
import Health from "../../assets/icons/glass/stethoscope-icon.webp";
import Ecom from "../../assets/icons/glass/laptop-icon.webp";
import Fintech from "../../assets/icons/glass/wallet-icon.webp";
import Travel from "../../assets/icons/glass/airplane-icon.webp"
import Estate from "../../assets/icons/glass/small-home-icon.webp"

export default function IndustriesBox({
  icon,
  title,
  subtitle,
  onMouseMove,
  onMouseEnter,
  onMouseLeave,
}) {
  let getIcon;
  const cardRef = useRef(null);

  switch (icon) {
    case "health":
      getIcon = <img src={Health} alt="icon" />;
      break;
    case "ecom":
      getIcon = <img src={Ecom} alt="icon" />;
      break;
    case "education":
      getIcon = <img src={Education} alt="icon" />;
      break;
    case "fintech":
      getIcon = <img src={Fintech} alt="icon" />;
      break;
    case "estate":
      getIcon = <img src={Estate} alt="icon" />;
      break;
    case "travel":
      getIcon = <img src={Travel} alt="icon" />;
      break;
    default:
      getIcon = <img src={Education} alt="icon" />;
      break;
  }

  return (
    <Wrapper
      className="flex flexColumn"
      ref={cardRef}
      onMouseMove={(e) => onMouseMove(e, cardRef)}
      onMouseEnter={(e) => onMouseEnter(e, cardRef)}
      onMouseLeave={() => onMouseLeave(cardRef)}
    >
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  // cursor: pointer;

  /* Gradient Effect - Hidden by default */
  --mouse-x: -9999px;
  --mouse-y: -9999px;

  background: radial-gradient(
      circle at var(--mouse-x) var(--mouse-y),
      #b10000 0%,
      #b1000050 10%,
      transparent 60%
    ),
    rgba(255, 255, 255, 0.03); /* Default translucent background remains */

  border-image-source: radial-gradient(
    circle at var(--mouse-x) var(--mouse-y),
    #b10000 0%,
    #b1000050 30%,
    transparent 50%
  );
  border-image-slice: 1;

  transition: background 0.3s, transform 0.3s;
`;
const IconStyle = styled.div`
  max-height: 40px;
  img {
    width: 2.5em;
  }
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  color: #b1000090;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
