import React from "react";
import Header from "../components/Marketing/Header";
import Contact from "../components/Home/Contact";
import CaseStudy from "../components/Services/CaseStudy";
import MarketingServices from "../components/Services/MarketingServices";
import DevelopmentServices from "../components/Services/DevelopmentServices";
import IndustriesWeWorkWith from "../components/Services/Industries";
import USP from "../components/Services/USP";

export default function Services() {
  return (
    <>
      <Header />
      <DevelopmentServices/>
      <IndustriesWeWorkWith/>
      <MarketingServices/>
      <USP/>
      <CaseStudy />
      <Contact />
    </>
  );
}
