import React from "react";
import styled from "styled-components";

 const AboutUs = () => {
  return (
    <Wrapper>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <Header>
            <h1 className="gradient-text">About Us</h1>
          </Header>
          <Content>
            <p>
              At <strong>CodezHub</strong>, we specialize in digital marketing
              and web development, helping businesses establish a strong online
              presence with innovative solutions.
            </p>
            <br />
            <Section>
              <h2>1. Who We Are</h2>
              <p>
                CodezHub is a team of experienced developers, designers, and
                marketing strategists dedicated to transforming ideas into
                powerful digital solutions.
              </p>
            </Section>

            <Section>
              <h2>2. What We Do</h2>
              <ul>
                <li>
                  <strong>Web Development:</strong> Custom websites, e-commerce
                  platforms, and web applications.
                </li>
                <li>
                  <strong>Digital Marketing:</strong> SEO, social media
                  marketing, paid advertising, and content strategies.
                </li>
                <li>
                  <strong>Branding & UI/UX Design:</strong> Creating compelling
                  brand identities and user-friendly designs.
                </li>
              </ul>
            </Section>

            <Section>
              <h2>3. Our Mission</h2>
              <p>
                To empower businesses with high-quality digital solutions that
                drive growth, engagement, and success.
              </p>
            </Section>

            <Section>
              <h2>4. Why Choose Us?</h2>
              <ul>
                <li>
                  ✔ <strong>Expert Team:</strong> Skilled professionals with
                  industry experience.
                </li>
                <li>
                  ✔ <strong>Results-Driven Approach:</strong> Strategies
                  designed to deliver measurable outcomes.
                </li>
                <li>
                  ✔ <strong>Client-Centric Solutions:</strong> Every project is
                  tailored to meet your unique business goals.
                </li>
              </ul>
            </Section>

            <Section>
              <h2>5. Get in Touch</h2>
              <p>
                Want to elevate your brand’s digital presence? Contact us at{" "}
                <strong>[your email]</strong> or visit{" "}
                <strong>[your website]</strong> to get started.
              </p>
            </Section>
          </Content>
        </div>
      </div>
    </Wrapper>
  );
}

export const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <Header>
            <h1 className="gradient-text">Privacy Policy</h1>
          </Header>
          <Content>
            <p>
              Your privacy is important to us. This policy outlines how we collect, use, and protect your personal information.
            </p>
            <br />
            <Section>
              <h2>1. Information We Collect</h2>
              <ul>
                <li><strong>Personal information:</strong> Name, email, phone when you contact us.</li>
                <li><strong>Usage data:</strong> Browser type, IP address to improve website experience.</li>
              </ul>
            </Section>
            <Section>
              <h2>2. How We Use Your Information</h2>
              <ul>
                <li>To provide and improve our services.</li>
                <li>To communicate with you regarding inquiries or service updates.</li>
                <li>To analyze website performance and user behavior.</li>
              </ul>
            </Section>
            <Section>
              <h2>3. Data Protection & Security</h2>
              <p>We implement strict security measures to protect your data and do not share it except as required by law.</p>
            </Section>
            <Section>
              <h2>4. Cookies & Tracking</h2>
              <p>Our website may use cookies to enhance your experience. You can manage cookie preferences in your browser settings.</p>
            </Section>
            <Section>
              <h2>5. Contact Us</h2>
              <p>For privacy-related concerns, reach out to <strong>info@codezhub.com</strong>.</p>
            </Section>
          </Content>
        </div>
      </div>
    </Wrapper>
  );
}

export const TermsOfService = () => {
  return (
    <Wrapper>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <Header>
            <h1 className="gradient-text">Terms of Service</h1>
          </Header>
          <Content>
            <p>By using CodeZHub’s website and services, you agree to the following terms:</p>
            <br />
            <Section>
              <h2>1. Acceptance of Terms</h2>
              <p>By accessing our website or using our services, you agree to comply with these terms. If you do not agree, please refrain from using our services.</p>
            </Section>
            <Section>
              <h2>2. Services & Usage</h2>
              <ul>
                <li>Our services include web development, digital marketing, and branding solutions.</li>
                <li>Unauthorized use of our content, trademarks, or intellectual property is strictly prohibited.</li>
              </ul>
            </Section>
            <Section>
              <h2>3. Payment & Refunds</h2>
              <p>Payments for services must be made as per the agreed terms. Refunds, if applicable, are subject to our refund policy.</p>
            </Section>
            <Section>
              <h2>4. Limitation of Liability</h2>
              <p>We are not liable for indirect, incidental, or consequential damages arising from the use of our services.</p>
            </Section>
            <Section>
              <h2>5. Updates & Modifications</h2>
              <p>We may update these terms at any time. Continued use of our services constitutes acceptance of the revised terms.</p>
            </Section>
            <Section>
              <h2>6. Contact Us</h2>
              <p>For any inquiries, contact us at <strong>info@codezhub.com</strong>.</p>
            </Section>
          </Content>
        </div>
      </div>
    </Wrapper>
  );
}

export const ContactUs = () => {
  return (
    <Wrapper>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <Header>
            <h1 className="gradient-text">Contact Us</h1>
          </Header>
          <Content>
            <p>We’d love to hear from you! Whether you have a project in mind, need assistance, or just want to connect, reach out to us.</p>
            
            <Section>
              <h2>1. Get in Touch</h2>
              <p><strong>Email:</strong> [your email]</p>
              <p><strong>Phone:</strong> [your phone number]</p>
              <p><strong>Address:</strong> [your office location]</p>
            </Section>

            <Section>
              <h2>2. Business Hours</h2>
              <p>Monday – Friday: 9 AM – 6 PM</p>
              <p>Saturday – Sunday: Closed</p>
            </Section>

            <Section>
              <h2>3. Send Us a Message</h2>
              <p>Fill out our contact form on <a href="[your website/contact page URL]" className="text-blue-500">our contact page</a>, and we’ll get back to you as soon as possible.</p>
            </Section>
          </Content>
        </div>
      </div>
    </Wrapper>
  );
};

export const HelpCenter = () => {
  return (
    <Wrapper>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <Header>
            <h1 className="gradient-text">Help Center</h1>
          </Header>
          <Content>
            <p>Need assistance? Find answers to common questions and get the support you need.</p>
            
            <Section>
              <h2>1. Frequently Asked Questions (FAQs)</h2>
              <ul>
                <li><strong>How do I start a project with CodezHub?</strong> Simply contact us via email or phone, and we’ll guide you through the process.</li>
                <li><strong>What services do you offer?</strong> We provide web development, digital marketing, branding, and UI/UX design services.</li>
                <li><strong>How can I track my project progress?</strong> We provide regular updates, reports, and direct communication with our team.</li>
              </ul>
            </Section>

            <Section>
              <h2>2. Customer Support</h2>
              <p><strong>Support Email:</strong> [your support email]</p>
              <p><strong>Support Hotline:</strong> [your support phone number]</p>
            </Section>

            <Section>
              <h2>3. Resources & Guides</h2>
              <p>Explore our blog and knowledge base for industry insights, tips, and best practices: <a href="[your website/help page URL]" className="text-blue-500">Visit Help Center</a></p>
            </Section>
          </Content>
        </div>
      </div>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: 100%;
  padding: 60px 20px;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.6;
  color: #fff;

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;

const Section = styled.div`
  margin-bottom: 30px;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #b1000099;
  }
  p {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #fff;
  }
`;

export default AboutUs