import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/logo/logocZh.svg";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { useLocation, useNavigate } from "react-router-dom";

export default function TopNavbar() {

  const location = useLocation();
  const navigate = useNavigate();
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} toggleDropdown={toggleDropdown} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate glass-nav"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            className="pointer flexNullCenter"
            to="home"
            onClick={()=> navigate("/")}
            smooth={true}
          >
            <img className="logo-i" src={LogoIcon} alt="" />
            {/* <h1 style={{ marginLeft: "3px" }} className="font20 extraBold">
              magination
            </h1> */}
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                onClick={()=> navigate("/")}
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Home
              </Link>
            </li>
            {/* <DropdownWrapper
              className="semiBold font15 pointer"
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <span style={{ padding: "10px 15px" }}>Services</span>
              {dropdownOpen && (
                <DropdownMenu>
                  <DropdownItem>
                    <Link
                      onClick={()=>{ 
                        navigate("/marketing");
                        setDropdownOpen(false)
                      }}
                      to="/marketing"
                      spy={true}
                      smooth={true}
                      offset={-80}
                    >
                      Marketing
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/development"
                      onClick={()=> {
                        setDropdownOpen(false)
                        navigate("/development")
                      }}
                      spy={true}
                      smooth={true}
                      offset={-80}
                    >
                      Development
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              )}
            </DropdownWrapper> */}
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
                onClick={()=>{ 
                  navigate("/services");
                }}
              >
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="projects"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Projects
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="blog"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Blog
              </Link>
            </li> */}
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="pricing"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Pricing
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {/* <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li> */}
            <li className="semiBold font15 pointer flexCenter">
              <Link
                smooth={true}
                to="contact"
                className="radius8 lightBg"
                style={{ padding: "10px 15px" }}
              >
                Get Started Now
              </Link>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
const DropdownWrapper = styled.li`
  position: relative;
  cursor: pointer;
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  // background-color: white;
  backdrop-filter: blur(8px);
  background-color: rgba(13, 13, 13, 0.5);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  z-index: 999;
`;

const DropdownItem = styled.li`
  padding: 10px 20px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  &:hover {
    background-color:rgb(0, 0, 0);
  }
`;
