import React, { useRef, useState } from "react";
import styled from "styled-components";
import TestimonialSlider from "../Elements/TestimonialSlider";
import FAQBox from "../Elements/FAQBox";

export default function FAQ() {

  const faqData = [
    {
      "question": "What services do you offer in digital marketing?",
      "answer": "We provide a range of digital marketing services, including SEO (Search Engine Optimization), PPC (Pay-Per-Click) advertising, social media marketing, email marketing, content creation, and analytics tracking. Our goal is to help you increase your online visibility and drive targeted traffic to your website."
    },
    {
      "question": "How long does it take to develop a website?",
      "answer": "The time required to develop a website depends on the complexity and requirements of the project. A standard business website typically takes 4-6 weeks, while more complex projects, like e-commerce websites or custom web applications, can take 8-12 weeks or longer. We'll provide a timeline after understanding your specific needs."
    },
    {
      "question": "Do you offer ongoing website maintenance and support?",
      "answer": "Yes, we offer ongoing maintenance and support services to ensure your website remains secure, updated, and optimized. This includes regular backups, software updates, bug fixes, and performance monitoring to keep your site running smoothly."
    },
    {
      "question": "How can digital marketing help my business grow?",
      "answer": "Digital marketing helps your business grow by increasing online visibility, driving targeted traffic to your website, and improving customer engagement. Through strategies like SEO, social media, and paid advertising, we can help you reach your target audience, generate leads, and increase sales."
    },
    {
      "question": "Can you redesign my existing website?",
      "answer": "Absolutely! We specialize in redesigning websites to make them more modern, user-friendly, and optimized for performance. Whether you need a visual refresh or a complete overhaul, we’ll ensure your new website aligns with your brand and business goals."
    }
  ]
  
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const handleMouseMove = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseEnter = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseLeave = (cardRef) => {
    cardRef.current.style.setProperty("--mouse-x", "-9999px");
    cardRef.current.style.setProperty("--mouse-y", "-9999px");
  };

  return (
    <Wrapper id="blog">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <center>
              <h1 className="font40 extraBold">
                Frequently Asked <span className="gradient-text">Questions</span> 
              </h1>
            </center>
          </HeaderInfo>
          <div className="container" data-aos="fade-up">
            <Advertising className="flexSpaceCenter">
              {/* <AddLeft>
                <img src={AddImage1} alt="office" />
              </AddLeft>
              <AddRight>
                
              </AddRight> */}
                <FAQSection>
                  {faqData.map((faq, index) => (
                      <FAQBox key={index}
                        data={faq}
                        index={index}
                        onMouseMove={handleMouseMove}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        activeAccordion={activeAccordion}
                        toggleAccordion={() => toggleAccordion(index)}
                      />  
                    )
                  )}
                </FAQSection>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
  }

  @media (max-width: 860px) {
    .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 3em;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  img {
    max-width: 20em;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 860px) {
    width: 100%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;

const FAQSection = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
`;