import React, { useRef } from "react";
import styled from "styled-components";
import USPBox from "../Elements/USPBox";

export default function USP() {
  const handleMouseMove = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseEnter = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Set mouse position for gradient effect
    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseLeave = (cardRef) => {
    // Reset gradient effect when leaving the card
    cardRef.current.style.setProperty("--mouse-x", "-9999px");
    cardRef.current.style.setProperty("--mouse-y", "-9999px");
  };

  return (
    <Wrapper id="why-choose">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h1 className="font40 extraBold">
              Why Choose <span className="gradient-text">CodeZhub?</span>
            </h1>
            <p className="font13">
              Here’s why we stand out with our unmatched dedication, reliability, and exceptional service.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <USPBox
                icon="health"
                title="Smart & Innovative Solutions"
                subtitle="We leverage the latest technology to build intelligent, user-friendly, and scalable solutions that empower businesses to stay ahead."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <USPBox
                icon="ecom"
                title="Data-Driven Marketing"
                subtitle="We don’t guess—we analyze. Our data-driven marketing strategies ensure that every campaign delivers measurable success, improving brand visibility and customer acquisition."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <USPBox
                icon="education"
                title="Customer-Centric Approach"
                subtitle="We work closely with our clients to understand their goals, challenges, and vision. Every solution we create is tailored to meet the unique needs of each business."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <USPBox
                icon="fintech"
                title="Future-Ready Technologies"
                subtitle="From AI to cloud computing, we integrate cutting-edge technologies that keep your business ahead of industry trends."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <USPBox
                icon="estate"
                title="Scalable & Secure Systems"
                subtitle="Security and scalability are at the core of our solutions. We build software that grows with your business while ensuring data protection and compliance."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <USPBox
                icon="travel"
                title="Seamless User Experience"
                subtitle="We focus on creating intuitive and visually appealing interfaces that enhance user experience, ensuring seamless interactions across all devices."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const HeaderInfo = styled.div`
  margin-bottom: 3em;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ServiceBoxRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  
  @media (max-width: 1160px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns for smaller screens
  }

  @media (max-width: 860px) {
    grid-template-columns: repeat(1, 1fr); // 1 column for mobile
    padding-top: 20px;
  }
`;

const ServiceBoxWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  text-align: center; // Align text in the center
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
