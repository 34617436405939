import React from "react";
import Header from "../components/Marketing/Header";
import Projects from "../components/Home/Projects";
import Contact from "../components/Home/Contact";
import WhyChooseUs from "../components/Marketing/WhyChooseUs";
import WhatWeOffer from "../components/Marketing/WhatWeOffer";
import CaseStudy from "../components/Marketing/CaseStudy";

export default function Marketing() {
  return (
    <>
      <Header />
      <WhatWeOffer/>
      <CaseStudy />
      <Contact />
    </>
  );
}
