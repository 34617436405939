import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
// Assets
import LogoIcon from "../../assets/logo/logocZh.svg";

export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const location = useLocation();

  return (
    <Wrapper>
      <TopSection>
        <Container>
          <LeftSection>
            <Link className="animate pointer" to="/">
              <img className="logo-i-footer" src={LogoIcon} alt="Logo" />
            </Link>
            {/* <p className="whiteColor font12 bold">info@codezhub.com</p> */}
          </LeftSection>
          <RightSection>
            <Column>
              <h4 className="whiteColor font15 extraBold">Company</h4>
              <ul>
                <li>
                  <NavLink to="/about-us" className="whiteColor font13">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/services" className="whiteColor font13">
                    Services
                  </NavLink>
                </li>
                {/* <li>
                  <Link to="/blog" className="whiteColor font13">
                    Blog
                  </Link>
                </li> */}
              </ul>
            </Column>
            <Column>
              <h4 className="whiteColor font15 extraBold">Support</h4>
              <ul>
                {location.pathname == "/" && <li>
                  <Link href="#faq" className="whiteColor font13">
                    FAQ
                  </Link>
                </li>}
                <li>
                  <NavLink to="/help" className="whiteColor font13">
                    Help Center
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" className="whiteColor font13">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </Column>
            <Column>
              <h4 className="whiteColor font15 extraBold">Legal</h4>
              <ul>
                <li>
                  <NavLink to="/privacy-policy" className="whiteColor font13">
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/terms" className="whiteColor font13">
                    Terms of Service
                  </NavLink>
                </li>
              </ul>
            </Column>
          </RightSection>
        </Container>
      </TopSection>
      <BottomSection>
        <Container className="flexSpaceBetween">
          <p className="whiteColor font13">
            All Rights Reserved © {getCurrentYear()}
          </p>
          <ScrollUpButton>
            <Link to="/">
              <svg
                width={36}
                height={36}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </Link>
          </ScrollUpButton>
        </Container>
      </BottomSection>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  width: 100%;
  background-color: #111;
  color: #fff;
`;

const TopSection = styled.div`
  padding: 40px 0;
`;

const BottomSection = styled.div`
  padding: 20px 0;
  border-top: 1px solid #333;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  padding: 0 20px;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100%;
  ustify-content: space-between;
  @media (max-width: 768px) {
    // justify-content: center;
    margin-bottom: 20px;
  }
`;

const RightSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 100%;
  }
`;

const Column = styled.div`
  h4 {
    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      a {
        text-decoration: none;
        color: #fff;
        &:hover {
          color: #d80a0a;
        }
      }
    }
  }
`;

const ScrollUpButton = styled.div`
  svg {
    cursor: pointer;
    &:hover {
      stroke: #d80a0a;
    }
  }
`;
