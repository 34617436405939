import React from "react";
import Header from "../components/Home/Header";
import Projects from "../components/Home/Projects";
import Pricing from "../components/Home/Pricing";
import Contact from "../components/Home/Contact";
import WhyChooseUs from "../components/Home/WhyChooseUs";
import HowWeWork from "../components/Home/HowWeWork";
import FAQ from "../components/Home/FAQ";

export default function Home() {
  return (
    <>
      <Header />
      <WhyChooseUs />
      <Projects />
      <HowWeWork />
      <Pricing />
      <Contact />
      <FAQ/>
    </>
  );
}
