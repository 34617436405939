import React, { useEffect } from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Nav/Footer";
import { useLocation } from "react-router-dom";
import EmptyNavbar from "../components/Nav/EmptyNavbar";

const MainLayout = (props) => {

  const location = useLocation();

  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location]);

  return (
    <>
      <TopNavbar />
      {props.children}
      <Footer />
    </>
  );
};

export const StaticLayout = (props) => {
  const location = useLocation();
  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location]);

  return (
    <>
      <EmptyNavbar />
      {props.children}
      <Footer />
    </>
  );
}

export default MainLayout;
