import React, { useState } from "react";
import styled from "styled-components";
// Assets
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Contact() {

  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (query.name && query.email && query.message && query.mobile) {
      await emailjs
        .send(
          "service_a3qmter",
          "template_g6bgix6",
          {
            name: query.name,
            email: query.email,
            mobile: query.mobile,
            message: query.message,
          },
          {
            publicKey: "KIwFVWrHIvBJuRuiE",
          }
        )
        .then(
          () => {
            // toast.success("Message Delivered");
            navigate("/thanks")
            setQuery({
              name: "",
              email: "",
              message: "",
              mobile: "",
            });
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } else {
      toast.error("Please fill all the fields");
    }
    setLoading(false);
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h2 className="font40 extraBold">Let’s Get <span className="gradient-text">Started!</span></h2>
            <p className="font13">
              Take the first step towards digital growth. 
              {/* <br /> */}
              Contact us today to discuss your project or request a quote.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">Name:</label>
                <input type="text" id="fname" name="name" onChange={handleChange} value={query.name} className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input type="email" id="email" name="email" onChange={handleChange} value={query.email} className="font20 extraBold" />
                <label className="font13">Mobile:</label>
                <input type="number" id="subject" name="mobile" onChange={handleChange} value={query.mobile} className="font20 extraBold" />
                <label className="font13">Message:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" onChange={handleChange} value={query.message} className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" disabled={loading} onClick={handleSubmit} style={{ maxWidth: "220px" }} />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 h-full w-full" style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
              <div class="calendly-inline-widget" data-url="https://calendly.com/codezhub/30min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=1a1a1a&text_color=ffffff&primary_color=b10000" style={{minWidth:"80%", height:"600px"}}></div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #ffffff;
    height: 30px;
    margin-bottom: 30px;
    color: #fff;
    font-size: 0.9em;
    font-weight: 100;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #B10000;
  background-color: #B10000;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #B1000050;
    border: 1px solid #B10000;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









