import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h2 className="font40 extraBold">Check Our <span className="gradient-text"> Pricing </span></h2>
            <p className="font13">
              No hidden fees—just straightforward pricing that fits your needs.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="$29,99/mo"
                title="Startup Plan"
                text="Ideal for small businesses looking to build their online presence with website development, SEO, and social media management.."
                offers={[
                  { name: "Basic website development", checked: true },
                  { name: "Social media setup & management", checked: true },
                  { name: "SEO optimization (On-page only)", checked: true },
                  { name: "Google My Business setup", checked: true },
                  { name: "Monthly performance report", checked: true },
                  { name: "Paid ad campaign management", checked: false },
                  { name: "Advanced SEO (On-page & Off-page)", checked: false },
                  { name: "Email marketing automation", checked: false },
                  { name: "Competitor analysis & strategy", checked: false },
                  { name: "Full-scale branding & design services", checked: false },
                  { name: "Conversion rate optimization (CRO)", checked: false },
                  { name: "Custom software & app development", checked: false },
                ]}
                action={() => {}}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="$49,99/mo"
                title="Growth Plan"
                text="Scale your brand with advanced digital marketing, custom development, and conversion optimization strategies."
                offers={ [
                  { name: "Basic website development", checked: true },
                  { name: "Social media setup & management", checked: true },
                  { name: "SEO optimization (On-page & Off-page)", checked: true },
                  { name: "Google My Business setup", checked: true },
                  { name: "Monthly performance report", checked: true },
                  { name: "Paid ad campaign management (Google & Social Media)", checked: true },
                  { name: "Advanced SEO (On-page & Off-page)", checked: true },
                  { name: "Email marketing automation", checked: true },
                  { name: "Competitor analysis & strategy", checked: true },
                  { name: "Full-scale branding & design services", checked: false },
                  { name: "Conversion rate optimization (CRO)", checked: false },
                  { name: "Custom software & app development", checked: false },
                ]}
                action={() => {}}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="$59,99/mo"
                title="Enterprise Solutions"
                text="Fully customized services tailored to your unique needs, including branding, automation, and high-level strategy."
                offers={ [
                  { name: "Basic website development", checked: true },
                  { name: "Social media setup & management", checked: true },
                  { name: "SEO optimization (On-page & Off-page)", checked: true },
                  { name: "Google My Business setup", checked: true },
                  { name: "Monthly performance report", checked: true },
                  { name: "Paid ad campaign management (Google & Social Media)", checked: true },
                  { name: "Advanced SEO (On-page & Off-page)", checked: true },
                  { name: "Email marketing automation", checked: true },
                  { name: "Competitor analysis & strategy", checked: true },
                  { name: "Full-scale branding & design services", checked: true },
                  { name: "Conversion rate optimization (CRO)", checked: true },
                  { name: "AI-driven marketing automation", checked: true },
                  { name: "Custom software & app development", checked: true },
                ]}
                action={() => {}}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




