import React, { useRef } from "react";
import styled from "styled-components";
import IndustriesBox from "../Elements/IndustriesBox"; // Assuming IndustriesBox is already created
import TechStack from "./TechStack";
import FullButton from "../Buttons/FullButton";
import AddImage2 from "../../assets/img/6.webp";

export default function IndustriesWeWorkWith() {
  const handleMouseMove = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseEnter = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Set mouse position for gradient effect
    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseLeave = (cardRef) => {
    // Reset gradient effect when leaving the card
    cardRef.current.style.setProperty("--mouse-x", "-9999px");
    cardRef.current.style.setProperty("--mouse-y", "-9999px");
  };

  return (
    <Wrapper id="industries-we-work-with">
      <div className="lightBg">
        <div className="container" data-aos="fade-up">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              {/* <h4 className="font15 semiBold">A few words about company</h4> */}
              <h2 className="font40 extraBold">
                Let’s <span className="gradient-text">Build</span> Something Amazing <span className="gradient-text">Together</span>
              </h2>
              <br />
              <p className="font12">
                Join hands with CodeZHub and experience the power of an all-in-one 360° marketing and software development solution. Whether you need a comprehensive digital marketing campaign or a state-of-the-art software solution, we’re here to turn your vision into reality.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
                    action={() => {}}
                  />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
                    action={() => {}}
                    border
                  />
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight>
              <ImgWrapper className="flexCenter">
                <img className="radius8" src={AddImage2} alt="add" />
              </ImgWrapper>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h2 className="font40 extraBold">
              Industries We <span className="gradient-text">Work With</span>
            </h2>
            <p className="font13">
              Our expertise extends across various industries, helping businesses leverage technology and digital marketing for growth.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <IndustriesBox
                icon="health"
                title="Healthcare & Telemedicine"
                subtitle="We build secure platforms for online consultations, patient management, and digital health services, ensuring a seamless experience for both healthcare providers and patients."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <IndustriesBox
                icon="ecom"
                title="E-commerce & Retail"
                subtitle="Our custom-built e-commerce stores and marketing strategies drive traffic, boost conversions, and create memorable shopping experiences."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <IndustriesBox
                icon="fintech"
                title="Finance & Fintech"
                subtitle="We develop secure financial applications, banking platforms, and fintech solutions that streamline transactions and enhance financial management."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <IndustriesBox
                icon="education"
                title="Education & E-learning"
                subtitle="We empower educators and institutions with interactive e-learning platforms that offer seamless online learning experiences."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <IndustriesBox
                icon="estate"
                title="Real Estate Management"
                subtitle="We create digital solutions for real estate businesses, enabling smooth property listing management, virtual tours, and customer engagement."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <IndustriesBox
                icon="travel"
                title="Travel & Hospitality"
                subtitle="Our travel and hospitality solutions enhance online booking experiences, streamline reservations, and improve customer satisfaction."
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <TechStack />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const HeaderInfo = styled.div`
  margin-bottom: 3em;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ServiceBoxRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  
  @media (max-width: 1160px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns for medium screens
  }

  @media (max-width: 860px) {
    grid-template-columns: repeat(1, 1fr); // 1 column for mobile
    padding-top: 20px;
  }
`;

const ServiceBoxWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  text-align: center; // Align text in the center
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

