import React from "react";
import styled from "styled-components";
import AddImage1 from "../assets/img/Woman2.webp";

export default function ThanksPage() {
  return (
    <>
      <Wrapper id="blog">
        <div className="whiteBg" style={{ paddingTop: "120px" }}>
          <div className="container" data-aos="fade-up">
            <HeaderInfo>
              <center style={{display:"flex", flexDirection:"column", gap:"2em", justifyContent:"center", alignItems:"center"}}>
                <h1 className="font40 extraBold">
                  <span className="gradient-text">Thanks!</span> We will get back to you.
                </h1>
                <img src={AddImage1} alt="" />
                <a
                  href="/"
                  className="radius8 lightBg"
                  style={{ padding: "10px 15px" }}
                >
                  Back To Home
                </a>
              </center>
            </HeaderInfo>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
  }

  @media (max-width: 860px) {
    .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 3em;
  @media (max-width: 860px) {
    text-align: center;
    img {
      max-width: 10em;
    }
  }
  img {
    max-width: 12em;
  }
`;
