import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import MainLayout, { StaticLayout } from "./Layout";
import Home from "./Home";
import Marketing from "./Marketing";
import Development from "./Development";
import Page404 from "./Page404";
import Services from "./Services";
import ThanksPage from "./ThanksPage";
import { ToastContainer } from "react-toastify";
import AboutUs, { ContactUs, HelpCenter, PrivacyPolicy, TermsOfService } from "./StaticPages";

const PublicRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
}) => {
  return (
    <Layout screenProps={ScreenProps}>
      <Component />
    </Layout>
  );
};

const Base = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            exact
            path={"/"}
            element={<PublicRoute component={Home} layout={MainLayout} />}
          />
          <Route
            exact
            path={"/marketing"}
            element={<PublicRoute component={Marketing} layout={MainLayout} />}
          />
          <Route
            exact
            path={"/development"}
            element={
              <PublicRoute component={Development} layout={MainLayout} />
            }
          />
          <Route
            exact
            path={"/services"}
            element={<PublicRoute component={Services} layout={MainLayout} />}
          />
          <Route
            exact
            path={"/thanks"}
            element={<PublicRoute component={ThanksPage} layout={StaticLayout} />}
          />
          <Route
            exact
            path={"/about-us"}
            element={<PublicRoute component={AboutUs} layout={StaticLayout} />}
          />
          <Route
            exact
            path={"/privacy-policy"}
            element={<PublicRoute component={PrivacyPolicy} layout={StaticLayout} />}
          />
          <Route
            exact
            path={"/terms"}
            element={<PublicRoute component={TermsOfService} layout={StaticLayout} />}
          />
          <Route
            exact
            path={"/help"}
            element={<PublicRoute component={HelpCenter} layout={StaticLayout} />}
          />
          <Route
            exact
            path={"/contact"}
            element={<PublicRoute component={ContactUs} layout={StaticLayout} />}
          />
          <Route
            exact
            path={"*"}
            element={<PublicRoute component={Page404} layout={StaticLayout} />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default Base;
