import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      <Link to="contact" smooth={true}>
        {title}
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#ffffff" : "#B10000")};
  background-color: ${(props) => (props.border ? "transparent" : "#B10000")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#ffffff" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#B1000050")};
    border: 1px solid #B10000;
    color: ${(props) => (props.border ? "#B10000" : "#fff")};
  }
`;

