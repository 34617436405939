import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";
import Marquee from "react-fast-marquee";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Working with codeZhub was the best decision for our business. Their digital marketing strategies boosted our website traffic by 150%, and the team was a delight to collaborate with."
            author="Sarah D. (Business Owner)"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Our new website is everything we wanted and more! The design is stunning, and the user experience is top-notch. Highly recommend their services!"
            author="Mark T. (Startup Founder)"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Their graphic design team brought our vision to life perfectly. From logos to marketing materials, everything exceeded our expectations."
            author="Priya S. (Marketing Manager)"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="We’ve seen amazing results since partnering with codeZhub. Their expertise in website development and SEO is unmatched."
            author="John L. (E-commerce Entrepreneur)"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
