import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/icons/Bitcoin.svg";
import CheckMark from "../../assets/icons/tick.svg";

export default function PricingTable({
  icon,
  price,
  title,
  text,
  offers,
  action,
}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = (
        // <RollerIcon />;
        <img src={RollerIcon} alt="icon" />
      );
      break;
    case "monitor":
      getIcon = <img src={RollerIcon} alt="icon" />;
      break;
    case "browser":
      getIcon = <img src={RollerIcon} alt="icon" />;
      break;
    case "printer":
      getIcon = <img src={RollerIcon} alt="icon" />;
      break;
    default:
      getIcon = <img src={RollerIcon} alt="icon" />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div
                className="flexNullCenter"
                style={{ margin: "15px 0" }}
                key={index}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "15px",
                  }}
                >
                  {item.checked ? (
                    <div style={{ minWidth: "20px" }}>
                      {/* <CheckMark /> */}
                      <img src={CheckMark} alt="icon" />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px", paddingLeft:"0.2em" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#b10000" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                    </div>
                  )}
                </div>
                <p style={{fontSize:"0.9em", fontWeight:"600"}}>{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div style={{ maxWidth: "100%", margin: "30px auto 0 auto" }}>
        <FullButton title="Buy" action={action} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;

  .flexSpaceCenter {
    display: flex;
    flex-direction:column;
  }
`;
