import React, { useRef } from "react";
import styled from "styled-components";
import TestimonialSlider from "../Elements/TestimonialSlider";
import AddImage1 from "../../assets/img/Woman1.webp";
import MarketingBox from "../Elements/MarketingBox";

export default function MarketingServices() {
  const handleMouseMove = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseEnter = (e, cardRef) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Set mouse position for gradient effect
    cardRef.current.style.setProperty("--mouse-x", `${x}px`);
    cardRef.current.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleMouseLeave = (cardRef) => {
    // Reset gradient effect when leaving the card
    cardRef.current.style.setProperty("--mouse-x", "-9999px");
    cardRef.current.style.setProperty("--mouse-y", "-9999px");
  };

  return (
    <Wrapper id="blog">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h1 className="font40 extraBold">
              <span className="gradient-text">Marketing </span>Services
            </h1>
            <p className="font13">
              Boost your brand's online success with tailored digital marketing
              services, including SEO, SMM, PPC,
              <br />
              content marketing, and personalized email campaigns.
            </p>
          </HeaderInfo>
          <div className="container" data-aos="fade-up">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <ServiceBoxRow className="flex">
                  <ServiceBoxWrapper>
                    <MarketingBox
                      icon="roller"
                      title="Search Engine Optimization"
                      subtitle="Rank higher on search engines and attract quality traffic."
                      onMouseMove={handleMouseMove}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </ServiceBoxWrapper>
                  <ServiceBoxWrapper>
                    <MarketingBox
                      icon="monitor"
                      title="Social Media Marketing (SMM)"
                      subtitle="Build a strong social presence and engage with your audience."
                      onMouseMove={handleMouseMove}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </ServiceBoxWrapper>
                  <ServiceBoxWrapper>
                    <MarketingBox
                      icon="browser"
                      title="Pay-Per-Click Advertising"
                      subtitle="Maximize ROI with targeted ad campaigns."
                      onMouseMove={handleMouseMove}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </ServiceBoxWrapper>
                  <ServiceBoxWrapper>
                    <MarketingBox
                      icon="printer"
                      title="Content Marketing"
                      subtitle="Deliver compelling content that converts."
                      onMouseMove={handleMouseMove}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </ServiceBoxWrapper>
                </ServiceBoxRow>
              </AddLeft>
              <AddRight>
                <img src={AddImage1} alt="office" />
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container" data-aos="fade-up">
          <HeaderInfo>
            <h2 className="font40 extraBold">What They Say?</h2>
            <p className="font13">
              We take pride in delivering seamless and secure crypto solutions.
              <br />
              But don’t just take our word for it—see what our clients have to
              say!
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
  }

  @media (max-width: 860px) {
    .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 3em;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ServiceBoxRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;

  @media (max-width: 860px) {
    padding-top: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  // padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  // width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  // width: 50%;
  position: absolute;
  // top: -70px;
  right: 0;
  img {
    max-width: 15em !important;
  }
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
    img {
      max-width: 10em !important;
    }
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
